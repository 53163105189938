.App { text-align: center;}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo { animation: App-logo-spin infinite 20s linear; }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link { color: #61dafb; }

@keyframes App-logo-spin {
  from { transform: rotate(0deg);   }
  to   { transform: rotate(360deg); }
}

li.item {
  list-style: none;
  width: 150px;
  color: #1A1A1A;
  background-color: #D5DDF6;
  border: 1px solid #97B0F8;
  border-radius: 2px;
  margin-bottom: 5px;
  padding: 5px 12px;
}

li.item:before {
  content: "≣";
  font-family: Arial, sans-serif;
  display: inline-block;
  font-size: inherit;
  cursor: move;
}

li.object-item {
  list-style: none;
  width: 150px;
  color: #1A1A1A;
  background-color: #D5DDF6;
  border: 1px solid #97B0F8;
  border-radius: 2px;
  margin-bottom: 5px;
  padding: 5px 12px;
}

li.object-item:before {
  content: "≣";
  font-family: Arial, sans-serif;
  display: inline-block;
  font-size: inherit;
  cursor: move;
}

.items-panel {
  display: flex;
  justify-content: space-around;
}

.module-active{
  background: black !important;
}