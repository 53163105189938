@keyframes sliderCardOpacity{ from{opacity: 1;} to{opacity: 0.5;}}
@keyframes sliderCardView{ from{opacity: 0.5;} to{opacity: 1;}}
@font-face {
  font-family: "Monserrat ProServicios";
  src: url("./fonts/static/Montserrat-Regular.ttf");
}

:root{
  --top-1-color: #ffd700;
  --top-2-color: #21262a;
  --top-3-color: #21262a;
  --top-4-color: #21262a;

  --left-1-color: linear-gradient(217deg, #445056, #171b1e 30.71%);
  --left-2-color: linear-gradient(217deg, #ffe96f, #ffd700 30.71%);
  --left-3-color: linear-gradient(217deg, #3b4043, #21262a 30.71%);
  --left-4-color: linear-gradient(217deg, #3b4043, #21262a 30.71%);

  --bkg-body-1: #f1f5f9;

  /* --bkg-select-primary: #ffe5d5; */
  /* --degPrimary: #171b1e; */
  /* --degSecond: #21262a; */
  --scroll-menu: #666666;
}

body {
  background-color:  var(--bkg-body-1) !important;
  margin: 0;
  font-family: 'Monserrat ProServicios', -apple-system, BlinkMacSystemFont, 'Monserrat ProServicios', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code { font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

/* width */
.tabScrollStyle::-webkit-scrollbar { width: 10px; }

/* Track */
.tabScrollStyle::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  background: rgb(248, 248, 248);
  border-radius: 10px;
}
 
/* Handle */
.tabScrollStyle::-webkit-scrollbar-thumb {
  background: rgb(172, 172, 172); 
  border-radius: 10px;
}

/* Handle on hover */
.tabScrollStyle::-webkit-scrollbar-thumb:hover { background: rgb(172, 172, 172); }





/* width */
.scroll-menu-modules::-webkit-scrollbar { width: 2px; }

/* Track */
.scroll-menu-modules::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  background: transparent;
  border-radius: 10px;
}
 
/* Handle */
.scroll-menu-modules::-webkit-scrollbar-thumb {
  background: var(--scroll-menu); 
  border-radius: 10px;
}

/* Handle on hover */
.scroll-menu-modules::-webkit-scrollbar-thumb:hover { background: var(--scroll-menu); }



/* width */
.scroll-user-modules::-webkit-scrollbar { width: 2px; }

/* Track */
.scroll-user-modules::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  background: transparent;
  border-radius: 10px;
}
 
/* Handle */
.scroll-user-modules::-webkit-scrollbar-thumb {
  background: #cbcbcb; 
  border-radius: 10px;
}

/* Handle on hover */
.scroll-user-modules::-webkit-scrollbar-thumb:hover { background: #cbcbcb; }





/* width */
.tabScrollStyleNotes textarea::-webkit-scrollbar { width: 5px; }

/* Track */
.tabScrollStyleNotes textarea::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  background: rgb(248, 248, 248);
  border-radius: 5px;
}
 
/* Handle */
.tabScrollStyleNotes textarea::-webkit-scrollbar-thumb {
  background: rgb(172, 172, 172); 
  border-radius: 5px;
}

/* Handle on hover */
.tabScrollStyleNotes textarea::-webkit-scrollbar-thumb:hover {background: rgb(172, 172, 172);}

#modal-modal-title{
  text-align: center;
  margin-bottom: 15px;
}

.select-bkg{ background-color: var(--bkg-select-primary);}
#select-modulo-to-submodulo{ padding: 10px !important; }

.select-card-media{
  background-color: black;
  opacity: 0.5;
  animation: sliderCardOpacity 0.5s;
}
.select-text-hidden{
  display: none;
  animation: sliderCardView 0.5s;
}
.footer-grid{
  text-align: center !important;
  display: grid !important;
  justify-content: center !important;
}

.footer-no-padding{
  padding: 0px !important;
  display: grid !important;
  align-content: center !important;
}

.sub-menu-deg svg{ color: black; }

.convection-img{
  max-width: 200px;
}

@media screen and (min-width: 900px) {
  .convection-img{
    max-width: 60px !important;
  }
}

@media screen and (min-width: 1080px) {
  .convection-img{
    max-width: 80px !important;
  }
}