.auth-inputs{width: 100%; padding-bottom: 25px;}
.auth-inputs-button:hover{
    box-shadow: 0px 0px 10px -5px black !important;
}
.auth-margin-top-inputs{margin-top: 5% !important;}
.auth-content-center{ align-content: center !important}
.auth-txt-center{ text-align: center !important}
.auth-section-space-container{height: 12.65vh}
.auth-container-login{
    width: 100%;
    margin: 0px !important;
    padding: 0px !important;
    max-width: none !important;
}
.auth-section-container{
    height: 103vh;
    background-size: cover
}
.auth-logo-container{
    background: white;
    display: inline-grid;
    padding: 20px 15px;
    border-radius: 100%;
}
.auth-inputs-container{
    padding: 0px !important; 
    margin: 0px !important;
    display: grid;
    align-content: center;
    justify-content: center;
    height: 100vh
}
.auth-inputs-sections{
    padding: 5% 15% !important;
    margin: 0px !important;
    background: white;
    border-radius: 10px;
}
.auth-inputs-button{
    margin-top: 20px !important;
    width: 100% !important;
    padding: 8px 0px !important;
    background: var(--top-1-color) !important;
    font-weight: bolder !important;
    box-shadow: 0px 0px 0px 0px black !important;
}
