.edit-form-container-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90vh;
    border-radius: 5px;
    background-color: white;
    box-shadow: 24;
    margin: 0px !important;
    padding: 0;
    border: 0px;
}

.new-recipe-container-modal{
    position: absolute;
    /* overflow-y: scroll; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90vh;
    border-radius: 5px;
    background-color: white;
    box-shadow: 24;
    /* padding: 10vh 5%; */
}

.recipe-container{
    position: fixed;
    z-index: 3;
    margin-top: 20px;
    padding-right: 3%;
    width: -webkit-fill-available;
    background: white;
    top: 30px;
    padding-top: 30px;
}

.recipe-container-space{
    background: white;
    padding-top: 40px;
}

.recipe-space{ margin: 15px 0px; }

.recipe-list{
    display: grid;
    /* padding: 30px 0px; */
}

.recipe-container-title{
    display: flex;
    margin-bottom: 15px;
}

.recipe-title{
    font-family: 'Encode Sans Condensed', sans-serif !important;
    margin-bottom: 15px !important;
    margin-right: 15px !important;
}

.recipe-btn-select{
    background: #377775 !important;
    padding: 20px !important;
    border-radius: 30% !important;
}

.recipe-btn{
    background: transparent !important;
    padding: 20px !important;
    border-radius: 30% !important;
    box-shadow: 0px 3px 7px -4px gray;
}

.recipe-btn:hover{
    background: #37777523 !important;
    padding: 20px !important;
    border-radius: 30% !important;
}

.recipe-precalentamiento-active{
    text-align: center;
    background: #aa3e27;
    padding: 10px;
    border-radius: 15px;
    color: white;
    cursor: pointer;
}

.recipe-precalentamiento{
    /* background: #aa3e27; */
    text-align: center;
    border: 1px solid #8e9092;
    padding: 10px;
    border-radius: 15px;
    cursor: pointer;
    color: #8e9092;
    box-shadow: 0px 2px 8px -5px black;
}

.disabled{
    cursor: inherit !important;
    background: #d9d9d9 !important;
    color: white !important;
    border: 0px !important;
}

.recipe-action-box #action{
    display: inline-flex !important;
}
